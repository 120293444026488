import React from 'react';
import { ChatIcon, TrashIcon } from '@heroicons/react/outline'

const CommentListItem = ({ comment, maxWidth, onDelete}) => {
  //fix " on comment
  console.log('key', comment)
  let commentText = comment.comment.replace("\"", '"')
  //fix linebreakd
  commentText = commentText.split('\\n').map(str => <p key={str} className="content__line">{str}</p>)
  //fix date
  const createdAtVal = new Date(comment.createdAt).toLocaleDateString('sv-SE');

  console.log('comment', comment)
  return (
    <li className={comment.usrType === 'employee' ? 'commentItem' : 'commentItem reverse'}>
      <div className="commentIcon"><ChatIcon className="standardSquareIcon" /></div>
      <div style={{maxWidth:maxWidth}}>
        {comment.showBin && <span className="pointer" onClick={onDelete}><TrashIcon className="standardSquareIcon" /> Radera</span>} 
        <div className="commentInfo">
        <span className="authorInfo">{comment.author} - {comment.organisation} </span>
        <span className="date">{createdAtVal}</span>
        </div>
        <div className="content" >{commentText}</div>
      </div>
    </li>

  )
}

export default CommentListItem;