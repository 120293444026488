export const TICKET_ID = 'TICKET_ID'
export const TICKET_COOP = 'TICKET_COOP'
export const TICKET_TYPE = 'TICKET_TYPE'
export const TICKET_ADDRESS = 'TICKET_ADDRESS'
export const TICKET_APARTMENT = 'TICKET_APARTMENT'
export const TICKET_STATUS = 'TICKET_STATUS'
export const TICKET_CREATED = 'TICKET_CREATED'
export const EDIT_TICKET = 'EDIT_TICKET'
export const REMOVE_ACTIVE_TICKET = 'REMOVE_ACTIVE_TICKET'
export const SET_TICKETS = 'SET_TICKETS'
export const TICKET_RESPONSIBLE = 'TICKET_RESPONSIBLE'


const ticketsReducerDefaultState = [];
const ticketsReducer = (state = ticketsReducerDefaultState, action) => {
  switch (action.type) {

    case 'EDIT_TICKET':
      return state.map((ticket) => {
        if (ticket.id === action.id) {
          return {
            ...ticket,
            ...action.updates
          }; // Update ticket with action.updates
        } else {
          return ticket; //this will do nothing at all, ticket is not changed
        }
      });
    case REMOVE_ACTIVE_TICKET:
      return state.filter((ticket) => {
        const ticketToRemove = (ticket.id === action.id) ? true : false
        if (ticketToRemove) {
          return false; //this will remove/filter out the ticket
        }else{
          return true //this will keep the ticket
        }
      });      
    case SET_TICKETS:
      //returning new array, don't care about what's instate
      return action.tickets;
    default:
      return state;
  }
};

export default ticketsReducer;