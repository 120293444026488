/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import ticketsReducer from '../reducers/tickets';
import ticketNotificationsReducer from '../reducers/ticketNotifications';
import filtersReducer from '../reducers/filters';
import userSettingsReducer from '../reducers/userSettings';
import uiReducer from '../reducers/ui';
import commentsReducer from '../reducers/comments';
import ticketsCompletedReducer from '../reducers/ticketsCompleted'; 
import modalsReducer from '../reducers/modals';

const disableReduxDevTools = false
//const disableReduxDevTools = (process.env.NODE_ENV === "production" && process.env.QA_MODE === false) // || process.env.NODE_ENV === "development")
const composeEnhancers = (disableReduxDevTools) ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  //Store creation with reducer, array is 'moved' from store into a property
  const store = createStore(
    combineReducers({
      auth: authReducer,
      tickets: ticketsReducer,
      ticketNotifications: ticketNotificationsReducer,
      filters: filtersReducer,
      usersettings: userSettingsReducer,
      ui: uiReducer,
      comments: commentsReducer,
      ticketsCompleted: ticketsCompletedReducer,
      modals: modalsReducer
      
    }), 
    composeEnhancers(applyMiddleware(thunk))
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  return store
}
