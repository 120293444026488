import {
  FILTER_TEXT, FILTER_RESPONSIBLE
} from '../reducers/filters'

export const filterTickets = (tickets, {filterType, text, filterValue} ) => {
  //let filteredTickets = tickets.filter((ticket) => {
    
    return tickets.filter((ticket) => {

    switch (filterType) {
      case FILTER_TEXT:
        //filtered keys: status, address
        if(ticket.statusName === null) {
          ticket.statusName = ''
        }
        if(ticket.address === null) {
          ticket.address = ''
        }
        if(ticket.descrFull === null) {
          ticket.descrFull = ''
        }         
      return (ticket.statusName.toLowerCase().includes(text.toLowerCase()) || ticket.address.toLowerCase().includes(text.toLowerCase()) || ticket.descrFull.toLowerCase().includes(text.toLowerCase()))
      case FILTER_RESPONSIBLE:
        return ticket.assignedToName === filterValue //TBD CHANGE TO RESPONSIBLE
      default:
        return []
    }
  })
}

