import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TicketTable from './ticketTable/TicketTable';
import Ticket from './ticket/Ticket';
import TicketTableActionRow from './ticketTable/TicketTableActionRow';
import { useWindowSize } from '../customHooks/useWindowSize'

import {
  TicketListLocation,
  TicketLocation
} from '../routers/RouteSetup'

import styles from '../styles/base/_settings.module.scss'

export const Dashboard = (props) =>   {
  const mediaSize = useSelector(state => state.ui.mediaSize)
  //espot vars and calc max-height minus header height & padding
  const styleVars = {
    spaceMedium: styles['space-medium'],
    spaceXL: styles['space-xl'],
    headerHeight: styles['header-height'].valueOf(),
  }

  //calc how max-height of col2(ticket container), add overflow if needed (via css)
  const screenHeight = useWindowSize().height
  //add some extra space on smaller screens to fix smartphone magic height calc
  const mediaSizeSpace = mediaSize === 'large' ? 0 : styleVars.spaceXL
  let col2MaxHeight = screenHeight - styleVars.spaceMedium - styleVars.headerHeight - mediaSizeSpace;
  
  return (
    <div>
      <Switch>
        <Route path={TicketListLocation.path} render={() =>
          <div className="mainContainer">
            <TicketTableActionRow />
            <div className="ticketTable__wrapper">
              <TicketTable />
            </div>
          </div>
        } exact={true} />
        <Route path={TicketLocation.path} render={() =>
          <div className="mainContainer">
            <div className="ticketTable__wrapper">

              <div className="ticketTable__container">
                <div className="ticketTable__col1" >
                  <TicketTable />
                </div>
                <div className="ticketTable__col2" style={{maxHeight: col2MaxHeight}}>
                  <div>
                    <Ticket />
                  </div>
                </div>
              </div>
            </div>
          </div>
        } exact={true} />
      </Switch>
    </div>
  )
}



export default Dashboard