import { SET_TICKET_NOTIFICATIONS } from '../reducers/ticketNotifications';
// import { setShowCompleted } from './ui'

//SET_TICKETS
export const setTicketNotifications = (ticketNotifications) => ({
  type: SET_TICKET_NOTIFICATIONS, 
  ticketNotifications
})

//fetch data and dispatch SET_TICKETS
export const startSetTicketNotifications = (uid) => {
  return (dispatch) => {
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ?'/test679Comments.json' : `${host}adm/getAjaxComments.php?uid=${uid}`

    // return fetch(`${host}adm/getAjaxComments.php?uid=${uid}`)
    return fetch(url)
    .then((response) => { return response.json() })
    .then((jsonResponse) => {
      const ticketNotifications = jsonResponse[0].tickets_with_new_comments
      //console.log('tickets data', tickets)
      return Promise.resolve(dispatch(setTicketNotifications(ticketNotifications)))
    })
    .catch((error) => console.log('error', error))
  }
}
