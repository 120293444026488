import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutIcon } from '@heroicons/react/outline';

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <p className="logoutWrapper" style={{"cursor":"pointer"}} onClick={() => logout({ returnTo: window.location.origin })}>
      <LogoutIcon className="standardSquareIcon" />
      Logga Ut
    </p>
  );
}
