export const SET_TICKET_NOTIFICATIONS = 'SET_TICKET_NOTIFICATIONS'

const ticketNotificationsReducerDefaultState = [];
const ticketNotificationsReducer = (state = ticketNotificationsReducerDefaultState, action) => {
  switch (action.type) {    
    case SET_TICKET_NOTIFICATIONS:
      //returning new array, don't care about what's instate
      return action.ticketNotifications;
    default:
      return state;
  }
};

export default ticketNotificationsReducer;