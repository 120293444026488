
const menuPos = (e, menuWidth = 165, numOfOptions = 3) => {
  let posX = e.nativeEvent.pageX
  //calculate if menu fits to the right of click-position, if not, move it to the left
  const closeToPageRight = (e.nativeEvent.pageX > window.innerWidth - menuWidth)
  if (closeToPageRight) {
    posX = window.innerWidth - menuWidth
  }

  //CALC MENU POSITION Y
  //open menu where user clicked
  let posY = e.nativeEvent.pageY
  const menuHeight = (numOfOptions * 48) //48px comes from css .modalBody.litItem - TBD can this be dynamic? 2 is number of options in menu
  //doesn't menu fit in space between user-click & page bottom?
  const closeToPageBottom = (e.nativeEvent.pageY > (window.innerHeight - menuHeight))

  //if too close to bottom, menu bottom is positioned at window-bottom
  if (closeToPageBottom) {
    posY = window.innerHeight - menuHeight
  }

  const modalPosition = { x: posX, y: posY }
  return modalPosition
}

export const calcs = {
  menuPos: menuPos
}
