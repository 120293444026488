import {ADD_COMMENT, DELETE_COMMENT, RESET_COMMENTS, SET_COMMENTS} from '../reducers/comments';

export const addComment = (comment) => ({
  type: ADD_COMMENT,
  comment
})
export const startAddComment = (commentData = {}) => {
  return (dispatch, getState) => {
    const {
      author='',
      comment = '',
      ticketId = '',
      uid = '',
      createdAt = Date.now()
    } = commentData

    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    // const url = (dataLocation === 'local') ? '/testSucessResponse.json' : `${host}/adm/goAjaxPortal.php?id=${ticketId}&com=${comment}&uid=${uid}`
    const url = (dataLocation === 'local') ? '/testSucessResponse.json' : `${host}/adm/goAjaxPortal.php?id=${ticketId}&uid=${uid}`

    // return fetch(`${host}/adm/goAjaxPortal.php?id=${ticketId}&com=${comment}&uid=${uid}`)
    /*
      const rawResponse = await fetch('https://httpbin.org/post', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({a: 1, b: 'Textual content'})
    });
    */

    return fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({comment: comment})
    })
    .then(response => response.json())    
    .then((jsonResponse) => {
      const success = jsonResponse[0].success
      if(success){
        const payload = { author, comment, ticketId, createdAt, showBin:true }
        const id = jsonResponse[0].id
        console.log('success!', { id, ...payload })
        dispatch(addComment({ id, ...payload }))
      }
    })

    /*
    return fetch(url)
    .then((response) => { 
      return response.json() 
    })
    .then((jsonResponse) => {
      const success = jsonResponse[0].success
      if(success){
        const payload = { author, comment, ticketId, createdAt, showBin:true }
        const id = jsonResponse[0].id
        console.log('success!', { id, ...payload })
        dispatch(addComment({ id, ...payload }))
      }
    })
    */

    /*
    //const uid = getState().auth.uid
    const payload = { author, comment, ticketId, createdAt }
    const id = 15 //mock
    //const apiInstruction = { action: DB_ACTION_ADD, payload, uid }
    //return commentAPI(apiInstruction)
    dispatch(addComment({ id, ...payload })) //Handled by subscription
   // .then(({ id, payload }) => {
      
      //.then(({ id, reduxStoreObj }) => {
        //dispatch(addComment({ id, ...reduxStoreObj })) //Handled by subscription
         
     /// })
     */
  }
}

export const deleteCommentActionGen = ({commentId}) => ({
  type: DELETE_COMMENT,
  commentId
})
export const deleteComment = (commentData = {}) => {
  return (dispatch, getState) => {
    const {
      commentId = '',
      ticketId = '',
      uid = ''
    } = commentData
    console.log('commentData', commentData)
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSucessResponse.json' : `${host}/adm/goAjaxPortal.php?id=${ticketId}&cid=${commentId}&act=delComm&uid=${uid}`
    return fetch(url)
    .then((response) => { 
      return response.json() 
    })
    .then((jsonResponse) => {
      const success = jsonResponse[0].success
      console.log('jsonResponse', jsonResponse)
      if(success){
        const payload = { commentId, ticketId }
        const id = jsonResponse[0].id
        console.log('success!', { id, ...payload })
        dispatch(deleteCommentActionGen({ commentId }))
      }
    })
    /*
    //const uid = getState().auth.uid
    const payload = { author, comment, ticketId, createdAt }
    const id = 15 //mock
    //const apiInstruction = { action: DB_ACTION_ADD, payload, uid }
    //return commentAPI(apiInstruction)
    dispatch(addComment({ id, ...payload })) //Handled by subscription
   // .then(({ id, payload }) => {
      
      //.then(({ id, reduxStoreObj }) => {
        //dispatch(addComment({ id, ...reduxStoreObj })) //Handled by subscription
         
     /// })
     */
  }
}

//SET_COMMENTS
export const setComments = (comments) => ({
  type: SET_COMMENTS, 
  comments
})


//fetch data and dispatch SET_COMMENTS
export const startSetComments = (ticketId, uid) => {
  return (dispatch) => {
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testCommentData.json' : `${host}/adm/getAjaxComments.php?&id=${ticketId}&uid=${uid}`
    // return fetch('/testCommentData.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
    // return fetch(`${host}/adm/getAjaxComments.php?&id=${ticketId}&uid=${uid}`)
    return fetch(url)
    .then((response) => { return response.json() })
    .then((comments) => {
      console.log('comments list', comments)
      const filteredComments = comments.filter(comment => comment.ticketId === ticketId)
      console.log('comment action', ticketId, filteredComments)
      return Promise.resolve(dispatch(setComments(filteredComments)))
    })
    .catch((error) => console.log('error', error))
   
  }
}

export const resetComments = () => ({
  type: RESET_COMMENTS
})