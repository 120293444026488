import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";

import { css } from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';

//want fullpagespinner? dont add area in promisetracker and LoadingSpinnerFullPage will load

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 2px auto;
    text-align: center;
`;

//show in area? <LoadingSpinnerComponent area="my-area" />
//trackPromise(..., 'my-area')
export const LoadingSpinnerComponent = (props) => {
  const { promiseInProgress } = usePromiseTracker({area: props.area});

  return (
    <PulseLoader
      css={override}
      sizeUnit={"rem"}
      size={15}
      color= {'#dbdbdb'}
      loading={promiseInProgress}
    />
  )
};