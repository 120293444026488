import React from 'react';
import CommentList from './CommentList';
import CommentAdd from './CommentAdd';

const CommentContainer = ({ ticketId }) => {

  return (
    <div className="commentContainer">
      <fieldset role="presentation">
        <legend>Kommentarer</legend>
        <CommentList ticketId={ticketId} />
        <CommentAdd ticketId={ticketId} />
      </fieldset>
      
    </div>
  )
}

export default CommentContainer;