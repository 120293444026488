export const SET_USER = 'SET_USER';

const authDefault = {
  isAuthenticated: false,
  isLoading: true
}

const authReducer = (state = authDefault, action) => {
  switch(action.type) {
    case SET_USER: 
    console.log(action)
    return {
      ...state, 
      isAuthenticated: action.isAuthenticated,
      isLoading:action.isLoading,
      coop: action.coop,
      nickname: action.nickname,
      name: action.name,
      picture: action.picture,
      updated_at: action.updated_at,
      usertype: action.usertype,
      email: action.email,
      email_verified: action.email_verified,
      sub: action.sub
    }
    default: 
    return state
  }
}

export default authReducer