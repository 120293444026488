export const SET_THEME = 'SET_THEME';

const userSettingsDefault = {
  theme: 'light'
}

const userSettingsReducer = (state = userSettingsDefault, action) => {
  switch(action.type) {
    case SET_THEME: 
    return {
      ...state, 
      theme:action.theme,
    }
    default: 
    return state
  }
}

export default userSettingsReducer