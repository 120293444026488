export const COMPLETE_TICKETS = 'COMPLETE_TICKETS';
export const SET_COMPLETED_TICKETS = 'SET_COMPLETED_TICKETS';


const ticketsCompletedReducerDefaultState = []

const ticketsCompletedReducer = (state = ticketsCompletedReducerDefaultState, action) => {
  switch (action.type) {
    case COMPLETE_TICKETS:
      return [
        ...state,
        action.ticketsCompleted
      ]
    case SET_COMPLETED_TICKETS:
      return action.ticketsCompleted


    default:
      return state
  }
}

export default ticketsCompletedReducer;
