import {
  SET_TEXT_FILTER, RESET_TEXT_FILTER, SET_FILTER_RESPONSIBLE, RESET_FILTER_RESPONSIBLE
} from '../reducers/filters'

export const setTextFilter = (text = '') =>(
  {
  type: SET_TEXT_FILTER,
  text
})

export const resetTextFilter = () => ({
  type: RESET_TEXT_FILTER
})

export const setFilterResponsible = (filterValue='') => (
  {
    type: SET_FILTER_RESPONSIBLE,
    filterValue
  }
)

export const resetFilterResponsible = () => ({
  type: RESET_FILTER_RESPONSIBLE
})
