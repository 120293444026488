
export const SET_MEDIA_SIZE = 'SET_MEDIA_SIZE';

export const MEDIA_LARGE = 'large'
export const MEDIA_MEDIUM = 'medium'
export const MEDIA_SMALL = 'small'

export const SHOW_COMPLETED = 'SHOW_COMPLETED';
export const SHOW_PRIVACY_INFO = 'SHOW_PRIVACY_INFO';

export const INITIAL_FETCH_COMPLETED_TICKETS = 'INITIAL_FETCH_COMPLETED_TICKETS'

const initialState = {
  mediaSize: MEDIA_LARGE,
  showCompleted: false,
  showPrivacyInfo: false,
  initTicketFetch: false,
}


const uiReducer = ( state=initialState, action ) => {
  switch ( action.type ) {
    
    case SET_MEDIA_SIZE:
      return {
        ...state,
        mediaSize: action.mediaSize
      }
        
    case SHOW_COMPLETED: 
      return {
        ...state, 
        showCompleted: action.showCompleted
      }

    case SHOW_PRIVACY_INFO: 
      return {
        ...state, 
        showPrivacyInfo: action.showPrivacyInfo
      }

    case INITIAL_FETCH_COMPLETED_TICKETS: 
      return {
        ...state, 
        initTicketFetch: action.completed
      }

    default:
      return state;
  }
};

export default uiReducer;