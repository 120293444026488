import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommentListItem from './CommentListItem';
import { resetComments, startSetComments } from '../../../actions/commentsActions';
import { deleteComment } from '../../../actions/commentsActions';

const CommentList = ({ticketId}) => {
  const dispatch = useDispatch()
  const uid = useSelector(state => state.auth.sub)
  const [runUglyAjaxLoop, setRunUglyAjaxLoop] = useState(true)
  useEffect(() =>{
    dispatch(startSetComments(ticketId, uid))
    ajax_ugly_poll_comments(ticketId, uid)
    // Stop getting comments on unmount
    return function cleanup() {
      // dispatch(startSetComments(0, uid))
      dispatch(resetComments())
      setRunUglyAjaxLoop(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId, uid, dispatch])

  //*** UGLY AJAX - NO WEBSOCKETS IN PHP
  let lastCommentCount = 0
  let commentCount = 0
  function ajax_ugly_poll_comments(ticketId, uid) {
    console.log('COMMENTS', lastCommentCount, commentCount)
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSuccessResponse.json' : `${host}/adm/getAjaxComments.php?&id=${ticketId}&uid=${uid}&fmt=count`
    
    //const url = `https://qa.beportal.se/adm/getAjaxComments.php?&id=${ticketId}&uid=${uid}&fmt=count`
    // return fetch(`${host}/adm/getAjaxComments.php?&id=${ticketId}&uid=${uid}&fmt=count`)
    return fetch(url)
    .then((response) => { 
      return response.json() 
    })
    .then((jsonResponse) => {
      lastCommentCount = commentCount
      commentCount = jsonResponse[0].count
      if(lastCommentCount !== commentCount){
        dispatch(startSetComments(ticketId, uid))
      }
      if(runUglyAjaxLoop){
        setTimeout(function () { ajax_ugly_poll_comments(ticketId, uid) }, 5000)
      }
    })
  }  
  //*** END UGLY AJAX

  const handleDelete = ({commentId, ticketId, uid}) => {
    console.log('handleDelete', {commentId, ticketId, uid})
    dispatch(deleteComment({commentId, ticketId, uid}))
  }

  const comments = useSelector(state => state.comments)
  console.log('comments', comments)

  const containerRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    setTimeout(() => { //without timeout calc is performed before el is fully rendered in DOM
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    }, 0)
  }, []);

  return (
    <div ref={containerRef} id='test'>
    {comments.length > 0 
      ? <ul className="commentList">
      {comments.map((comment) => {
        console.log('comment id',comment.id, comment)
        return (
          <CommentListItem key={comment.id} comment={comment} maxWidth={dimensions.width} onDelete={() => handleDelete({commentId:comment.id, ticketId, uid})}/>
        )
      })}
    </ul>
      : <p>Inga kommentarer ännu</p>
    }
     
    </div>
  )
}

export default CommentList