// import { useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { EDIT_TICKET, SET_TICKETS, REMOVE_ACTIVE_TICKET } from '../reducers/tickets';
import { COMPLETE_TICKETS, SET_COMPLETED_TICKETS } from '../reducers/ticketsCompleted';
// import { SHOW_COMPLETED } from '../reducers/ui';
import { setShowCompleted, setinitTicketFetchCompleted } from './ui'

//SET_TICKETS
export const setTickets = (tickets) => ({
  type: SET_TICKETS, 
  tickets
})


//fetch data and dispatch SET_TICKETS
export const startSetTickets = (uid) => {
  // console.log('startSetTickets', uid)
  return (dispatch) => {
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testDataFull.json' : `${host}/adm/gridAjaxPortal.php?grid=reportActive&uid=${uid}&fmt=full`
    
    // return fetch(`${host}/adm/gridAjaxPortal.php?grid=reportActive&uid=${uid}&fmt=full`)
    // const url = 'https://qa.beportal.se/adm/gridAjaxPortal.php?grid=reportActive&uid=auth0|60abad43ff2d180069b84b8a&fmt=full'
    // return fetch('/testDataFull.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
    return trackPromise(
      fetch(url)
      .then((response) => { return response.json() })
      .then((tickets) => {
        //console.log('tickets data', tickets)
        dispatch(setinitTicketFetchCompleted(true))
        return Promise.resolve(dispatch(setTickets(tickets)))
      })
      // .catch((error) => console.log('error', error))
    , 'ticket-list')
  }
}

//EDIT_TICKET
export const editTicket = ({id, updates}) => ({
  type: EDIT_TICKET, 
  id,
  updates
})
//fetch data and dispatch EDIT_TICKET
export const startEditTicket = ({id=0, updates={}}) => {
  return (dispatch) => {
    return dispatch(editTicket({id, updates}))
  }
}

//COMPLETED
//SET_COMPLETED_REMINDERS
export const setCompletedTickets = (ticketsCompleted) => ({
  type: SET_COMPLETED_TICKETS,
  ticketsCompleted
})

export const startSetCompletedTickets = (uid) => {
  // thunk gives access to store methods dispatch and getState
  //set auth
  console.log('in archive!')
  //return (dispatch, getState) => {

    return (dispatch) => {
      const host = process.env.REACT_APP_AJAX_HOST
      const dataLocation = process.env.REACT_APP_DATA_LOCATION
      const url = (dataLocation === 'local') ? '/testDataArchive.json' : `${host}/adm/gridAjaxPortal.php?grid=reportArchive&uid=${uid}&fmt=full`
      
      // return fetch(`${host}/adm/gridAjaxPortal.php?grid=reportArchive&uid=${uid}&fmt=full`)
      // return fetch('/testDataArchive.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
      return fetch(url)
      .then((response) => { return response.json() })
      .then((tickets) => {
        //console.log('tickets data', tickets)
        return Promise.resolve(dispatch(setCompletedTickets(tickets)))
      }).then(() => {
        return Promise.resolve(dispatch(setShowCompleted(true)))
      }).catch((error) => console.log('error', error))
     
    }
   // const apiInstruction = { action: DB_ACTION_GET_ONCE_ARCHIVE, payload, uid: getState().auth.uid }

    // return trackPromise(
    //   reminderAPI(apiInstruction)
    //   .then((remindersCompleted) => {
    //     //get data into redux
    //     const promise = new Promise((resolve, reject) => {
    //       // console.log('remindersCompleted', remindersCompleted)
    //       dispatch(actionGenGetCompletedReminders(remindersCompleted))
    //       .then(resolve(true))
    //     })
    //     return promise
    //   })
    //   , 'completedReminder-list'
    // )
  //}
}

//COMPLETE_TICKETS
//support for multiple?
export const completeTickets = (ticketToMove) => ({
  type: COMPLETE_TICKETS,
  ticketsCompleted: ticketToMove
})

//REMOVE_TICKET
//support for multiple?
export const removeActiveTicket = (id) => ({
  type: REMOVE_ACTIVE_TICKET,
  id
})

export const startCompleteTickets = ({ticketId=0, ticketToMove=null, uid=''}) => {
  return (dispatch) => {
    console.log('id to complete', ticketId)
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSuccessResponse.json' : `${host}/adm/goAjaxPortal.php?act=check&id=${ticketId}&uid=${uid}`

    // return fetch(`${host}/adm/goAjaxPortal.php?act=check&id=${ticketId}&uid=${uid}`)
    // return fetch('https://qa.beportal.se/adm/goAjaxPortal.php?act=check&id=41&uid=auth0|609052f361dd7400692b348a', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
    return fetch(url)
    .then((response) => { return response.json() })
    .then((jsonResponse) => {
      const success = jsonResponse[0].success
      if(success){
        dispatch(removeActiveTicket(ticketId))
        return Promise.resolve(dispatch(completeTickets(ticketToMove)))
      }else{
        return Promise.resolve(false)
      }
    }).catch((error) => console.log('error', error))
  }
}