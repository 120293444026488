import React, {useRef, useState, useLayoutEffect} from 'react';
import { useParams } from "react-router-dom";
import { KeyIcon } from '@heroicons/react/outline';
import { CogIcon } from '@heroicons/react/outline';
import { ChatIcon } from '@heroicons/react/outline';

export const TicketTableRow = ({ ticket, handleRowClick, userType }) => {
  let { ticketId } = useParams();
  //performer has 1 more table column
  //const colSpan = userType === 'performer' ? 7 : 6 

  let colSpan = 6
  //perfomer has extra column 'BRF'
  if(userType === 'performer') {
    colSpan = 7
  } 
  //portalAdmin has extra columns 'BRF' & 'Ansvarig'
  if(userType === 'portalAdmin') {
    colSpan = 8
  }
    //calc container width
    const tRef = useRef(null)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    useLayoutEffect(() => {
      if (tRef.current) {
        setDimensions({
          width: tRef.current.offsetWidth - 20,
          height: tRef.current.offsetHeight
        });
      }
    }, []);
  return (
    <tbody className={ ticketId === ticket.id.toString() ? "ticketTable__row selected" : "ticketTable__row"} onClick={() => handleRowClick(ticket.id)} ref={tRef}>
      <tr>
        <td className="mainTd" title={ticket.reportTypeName}>{(ticket.reportType === 1 || ticket.reportType === 2) ? <KeyIcon className="standardSquareIcon"/> : <CogIcon className="standardSquareIcon"/> }<br/>
        {ticket.hasNewComment && <ChatIcon className="standardSquareIcon  standardSquareIcon--notification"/>}
        </td>
        {userType !=='objectUser' && <td className="mainTd">{ticket.coop}</td>}
        {userType === 'portalAdmin' && <td className="mainTd">{ticket.assignedToName}</td>}
        <td className="mainTd">{ticket.created}</td>
        <td className="mainTd">{ticket.statusName}</td>
        <td className="mainTd">{ticket.id}</td>
        <td className="mainTd">{ticket.address}</td>
        <td className="mainTd">{ticket.apartment}</td>
      </tr>
      <tr className="descRow"><td colSpan={colSpan}><p className="descTitle">Beskrivning:</p><div className="desc" style={{ maxWidth: dimensions.width }}>{ticket.descr}</div></td></tr>
      </tbody>
 
  )
}