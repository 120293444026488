export const FILTER_TEXT = 'FILTER_TEXT'
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER'
export const RESET_TEXT_FILTER = 'RESET_TEXT_FILTER' //Same as SET_STARTPAGE_FILTER

export const FILTER_RESPONSIBLE = 'FILTER_RESPONSIBLE'
export const SET_FILTER_RESPONSIBLE = 'SET_FILTER_RESONSIBLE'
export const RESET_FILTER_RESPONSIBLE = 'RESET_FILTER_RESPONSIBLE'

const filtersReducerDefaultState = {
  filterType: '',
  text: '',
  filterValue:''
}

const filtersReducer = (state = filtersReducerDefaultState, action) => {
  switch(action.type) {
    case SET_TEXT_FILTER: 
    return {
      ...state, 
      filterType: FILTER_TEXT,
      text:action.text,
    }
    case RESET_TEXT_FILTER: 
    return {
      ...state, 
      text:''
    }
    case SET_FILTER_RESPONSIBLE:
      return {
        ...state, 
        filterType:FILTER_RESPONSIBLE,
        filterValue:action.filterValue, //? 
        text: ''
      }
    case RESET_FILTER_RESPONSIBLE: //TBD make generic RESET_FILTER
      return {
        ...state, 
        filterValue:'',
        filterType:''
      }
    default: 
    return state
  }
}

export default filtersReducer;