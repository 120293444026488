export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const RESET_COMMENTS = 'RESET_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';

const commentsReducerDefaultState = [];

const commentsReducer = (state = commentsReducerDefaultState, action) => {
  console.log('action', action)
  switch (action.type) {
    case SET_COMMENTS:
      //returning new array, don't care about what's instate
      return action.comments;
    case ADD_COMMENT:
      return [
        ...state,
        action.comment
      ]
    case DELETE_COMMENT:
      return state.filter((comment) => comment.id !== action.commentId)
    case RESET_COMMENTS:
      return []
      
    default:
      return state;
  }

}

export default commentsReducer;