import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import configureStore from './store/configureStore';
import './styles/styles.scss';
import reportWebVitals from './reportWebVitals';
// import { startSetTickets } from './actions/ticketsActions';

const store = configureStore();

export const history = createBrowserHistory();
const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const jsx = (
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    // domain="dev-nigwuo9b.eu.auth0.com"
    // clientId="UdVndpJvsZUPGIYp75ax9FWpPrsll6qI"

    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <AppRouter history={history} />
    </Provider>
  </Auth0Provider>
)

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true
  }
}

ReactDOM.render(<p>Laddar</p>, document.getElementById('root'))

renderApp()

/* This must be loaded in AppRouter due to restrictions in auth0 and hooks
1. Use the useAuth0 hook in your components to access authentication state (isLoading, isAuthenticated and user) and authentication methods (loginWithRedirect and logout)
2. React Hook "useEffect" cannot be called at the top level. React Hooks must be called in a React function component or a custom React Hook function.

store.dispatch(startSetTickets()).then(() => {
  renderApp()
  //is user on login= redirect to start
  // if ( history.location.pathname === '/') {
  //   history.pushState('/start')
  // }
})
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
