import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePromiseTracker } from "react-promise-tracker";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { TicketListLocation } from '../../routers/RouteSetup';
import CommentContainer from './comments/CommentContainer';
import { XIcon } from '@heroicons/react/outline'
import { CheckIcon } from '@heroicons/react/outline'
import { startCompleteTickets } from '../../actions/ticketsActions';
import { LoadingSpinnerComponent } from '../LoadingSpinnerComponent'


const Ticket = () => {
  const mediaSize = useSelector(state => state.ui.mediaSize)
  const isInCompleted = useSelector(state => state.ui.showCompleted)
  const userType = useSelector(state => state.auth.usertype)
  const dispatch = useDispatch()
  const [dimensions, setDimensions] = useState(0);

  //useCallback info
  //we can't use a regular useEffect as our dependency would be a ref, 
  //and the ref isn't updated until the component has rendered 
  //— meaning, any useEffect that skips rendering, won’t see any changes to the ref before the next render pass.
  //so instead we use useCallback and React will call that callback whenever the ref gets attached to a different node
  //Note that we pass [] as a dependency array to useCallback. 
  //This ensures that our ref callback doesn’t change between the re-renders, and so React won’t call it unnecessarily.
  //In our ticket component, the callback ref will be called only when the component mounts and unmounts, 
  //since the rendered ticket component stays present throughout any rerenders. 
  //If you want to be notified any time a component resizes, you may want to use ResizeObserver or a third-party Hook built on it.

  //set width of ticket
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setDimensions(node.getBoundingClientRect().width);
    }
  }, []);
  

  const { promiseInProgress } = usePromiseTracker({area: 'ticket-list'});

  let location = useLocation()
  let locationId = location.pathname.split("/").pop()

  //scroll to top if ticket is open on small screen (browser default is to preserve scroll position)
  useEffect(() => {
    if (mediaSize !== 'large') { window.scrollTo(0, 0); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  const activeTickets = useSelector(state => state.tickets)
  const completedTickets = useSelector(state => state.ticketsCompleted)
  const uid = useSelector(state => state.auth.sub)

  const tickets = isInCompleted ? completedTickets : activeTickets

  let history = useHistory();
  const toList = () => {
    history.push((`${TicketListLocation.toUrl()}`))
  }

  const selectedTicket = tickets.find(ticket => ticket.id.toString() === locationId)

  const handleCompleteTicket = () => {
    const ticketToMove = tickets.find((ticket) => ticket.id === selectedTicket.id)
    dispatch(startCompleteTickets({ticketId:selectedTicket.id, ticketToMove, uid:uid}))
    toList()
  }

  const showCompleteBtn = ((userType === 'performer' || userType === 'portalAdmin') &! isInCompleted) ? true : false

  if(promiseInProgress){
    return(
      <div className="loaderContainer">
        <LoadingSpinnerComponent area="ticket-list"/>
      </div>
    )
  }
  if (selectedTicket !== undefined) {
    return (
      <React.Fragment>
        <div className="closeTicket">
          <span className="closeTicket__button" onClick={toList} role="button" title='Stäng'><XIcon className="standardSquareIcon" /></span>
        </div>
        <div className="ticketContainer">
          {/*<div ref={ticketRef}>*/}
          <div ref={measuredRef}>
            {showCompleteBtn && <div className="completeTicket">
              <span className="completeTicket__button" onClick={handleCompleteTicket} role="button"><CheckIcon className="standardSquareIcon standardSquareIcon--primary" /><span>Avsluta ärende</span></span>
            </div>}
            <fieldset role="presentation" style={{ maxWidth: dimensions }} >
              <legend>#{selectedTicket.id} | {selectedTicket.reportTypeName} | {selectedTicket.created}</legend>
              <div className="displayGroup">
                <div className="displayGroup__item"><span className="itemLabel">Namn</span><p className="itemData">{selectedTicket.firstName} {selectedTicket.lastName}</p></div>
                <div className="displayGroup__item"><span className="itemLabel">Telefon</span><p className="itemData">{selectedTicket.phone}</p></div>
              </div>
              <div className="displayGroup">
                <div className="displayGroup__item"><span className="itemLabel">Lgh.nr/utrymme</span><p className="itemData">{selectedTicket.apartment}</p></div>
                <div className="displayGroup__item"><span className="itemLabel">E-post</span><p className="itemData">{selectedTicket.email}</p></div>
              </div>
              <div className="displayGroup__item">
                <span className="itemLabel">Ärende</span>
                <div className="itemData descriptionArea" style={{ maxWidth: dimensions }} disabled>{selectedTicket.descrFull}</div>
              </div>
              <CommentContainer ticketId={selectedTicket.id} />
            </fieldset>

          </div>
        </div>
      </React.Fragment>
    )
  }
  return <p>Hittade inte felan :-(</p>
}
export default Ticket