import { Redirect, Route } from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Dashboard from '../components/Dashboard'

// This regex helper replaces ':ticketId' in 'path' using an object and returns the path
// path:'/ticket/:ticketId' --> .toUrl({ticketId:'1234'}) --> returns '/ticket/1234'
const replacer = function(tpl, data) {
  // console.log('replacer', tpl, data)
  var re = /\:([^]+)?/g, match;
  while(match = re.exec(tpl)) {
    tpl = tpl.replace(match[0], data[match[1]])
    re.lastIndex = 0;
  }
  return tpl;
}

export const TicketLocation = { path:'/ticket/:ticketId', toUrl: function(urlObj){return replacer(this.path, urlObj)}}
export const TicketListLocation = { path:'/list/', toUrl: function(){return this.path}}

export const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

export const PublicRoute = ({ component, isAuthenticated=false, ...args }) => {
  return (!isAuthenticated) ? (
    <Route component={component} {...args} />
    ):(
    // <Redirect to={{ pathname: redirectPath, state: {from: location} }} />
    // <Redirect to={{ pathname: redirectPath }} />
    <Redirect to="/" />
  ) 
};

export const PrivateDashboard = withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <div>Skickar dig till inloggningen...</div>
})

/*
export const AuthRoute = props => {
  const { isAuthUser, type } = props;
  if (type === "guest" && isAuthUser) return <Redirect to="/home" />;
  else if (type === "private" && !isAuthUser) return <Redirect to="/" />;

  return <Route {...props} />;
}
*/