import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from 'react-redux'
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import PrivacyModal from '../components/PrivacyModal';
import { LoginButton } from '../components/auth/LoginButton'
import { LogoutButton } from '../components/auth/LogoutButton'
import { Profile } from '../components/auth/Profile'

import {
  ProtectedRoute,
  TicketListLocation, //list
  TicketLocation,
  PrivateDashboard
} from './RouteSetup'
import Header from '../components/header/Header'
// import Dashboard from '../components/Dashboard'

import { setUser } from '../actions/authActions'
import { startSetTickets } from '../actions/ticketsActions'
import { setTicketNotifications } from "../actions/ticketNotificationsActions";
import { setMediaSize, setShowPrivacyInfo, startSetShowPrivacyInfo } from '../actions/ui'

import { MEDIA_LARGE, MEDIA_MEDIUM, MEDIA_SMALL } from '../reducers/ui'

import { useWindowSize } from '../customHooks/useWindowSize'

//Import scss styles (breakpoints for different units)
import styles from '../styles/base/_settings.module.scss'
//TBD get theme from usersettings




const AppRouter = ({ history}) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  //redux
  const mediaSize = useSelector(state => state.ui.mediaSize)
  const dispatch = useDispatch()
  const theme = useSelector(state => state.usersettings.theme) //poc, not used in forstena

  const showModal = useSelector(state => state.ui.showPrivacyInfo)
  const [modalIsOpen, setModalIsOpen] = useState(showModal)
  const handlePrivacyConfirm = () => {
    const uid = user?.sub ?? ''
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSuccessResponse.json' : `${host}/adm/goPrivacy.php?uid=${uid}&act=stamp`

    // /adm/goPrivacy.php?uid=auth0|605512982330600070b49197&act=read
    return fetch(url)
    .then((response) => { 
      return response.json() 
    })
    .then((jsonResponse) => {
      console.log('jsonResponse', jsonResponse)
      const success = (jsonResponse[0].success)
      if(success){
        dispatch(setShowPrivacyInfo(false))
      }
    })
  }

  useEffect(() => {
    setModalIsOpen(showModal)
  }, [showModal])

   //** Start mediaQuery */
   const mediaBreakpoints = {
    smallStr: styles['breakpoint-small-value'],
    mediumStr: styles['breakpoint-medium-value'],
    largeStr: styles['breakpoint-large-value'],
    smallInt: parseInt(styles['breakpoint-small-value'], 10), 
    mediumInt: parseInt(styles['breakpoint-medium-value'], 10), 
    largeInt: parseInt(styles['breakpoint-large-value'], 10), 
    small: styles['breakpoint-small'],
    medium: styles['breakpoint-medium'],
    large: styles['breakpoint-large']
  }



  //Calculate media size
  const screenWidth = useWindowSize().width //const screenWidth = size.width
 
  useEffect(() => {

    if (screenWidth > mediaBreakpoints.largeInt) {
      if (mediaSize !== MEDIA_LARGE) { dispatch(setMediaSize(MEDIA_LARGE)) }
    } else if (screenWidth > mediaBreakpoints.mediumInt) { 
      if (mediaSize !== MEDIA_MEDIUM) { dispatch(setMediaSize(MEDIA_MEDIUM)) }
    } else if (screenWidth > mediaBreakpoints.smallInt) { 
      if (mediaSize !== MEDIA_SMALL) { dispatch(setMediaSize(MEDIA_SMALL)) }
    } else { 
      //Tiny and small are equal
      if (mediaSize !== MEDIA_SMALL) { dispatch(setMediaSize(MEDIA_SMALL)) }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth])

  //*** UGLY AJAX - NO WEBSOCKETS IN PHP
  const uglyPollTime = 30000
  const [initialPollCompleted, setInitialPollCompleted] = useState(false)

  // Set user info in store when user logs in / out
  useEffect(() => {
    // console.log('auth0 user', user);
    let tidyUser = {...user}
    tidyUser.nickname = tidyUser.name
    tidyUser.usertype = (tidyUser['https://my.metadata.com/usertype']) ? tidyUser['https://my.metadata.com/usertype'] : 'ERROR'
    tidyUser.coop = (tidyUser['https://my.metadata.com/coopnames']) ? tidyUser['https://my.metadata.com/coopnames'][0] : ''
    delete tidyUser['https://my.metadata.com/usertype']
    delete tidyUser['https://my.metadata.com/coops']
    delete tidyUser['https://my.metadata.com/coopnames']

    // console.log('tidyUser', tidyUser);

    const uid = tidyUser.sub
    // Sanity check - only dispatch if/when we have a uid
    if(uid){
      dispatch(startSetShowPrivacyInfo(uid))
      dispatch(setUser({isAuthenticated, isLoading, user:tidyUser}))
      if(!initialPollCompleted){
        dispatch(startSetTickets(uid)).then(() => {
          setTimeout(()=>{ajax_ugly_poll_tickets(uid)}, 0);
          setTimeout(()=>{ajax_ugly_poll_new_comments(uid)}, 0);          
        })
        setInitialPollCompleted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  //*** UGLY AJAX - NO WEBSOCKETS IN PHP
  let lastTicketCount = 0
  let ticketCount = 0
  function ajax_ugly_poll_tickets(uid) {
    // console.log('TICKETS', lastTicketCount, ticketCount)
    // console.log('TICKETS', uid)
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSuccessResponse.json' : `${host}/adm/gridAjaxPortal.php?grid=reportActive&uid=${uid}&fmt=count`

    // return fetch(`${host}/adm/gridAjaxPortal.php?grid=reportActive&uid=${uid}&fmt=count`)
    return fetch(url)
    .then((response) => { 
      return response.json() 
    })
    .then((jsonResponse) => {
      lastTicketCount = ticketCount
      ticketCount = jsonResponse[0].count
      if(lastTicketCount !== ticketCount){
        dispatch(startSetTickets(uid))
      }
      setTimeout(function () { ajax_ugly_poll_tickets(uid) }, uglyPollTime)
    })
  }

  let lastCommentCount = 0
  let commentCount = 0
  let lastUnreadCommentsArray = []
  let unreadCommentsArray = []
  function ajax_ugly_poll_new_comments(uid) {
    console.log('UNREAD COMMENTS', lastCommentCount, commentCount)
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSuccessResponse.json' : `${host}/adm/getAjaxComments.php?uid=${uid}`

    // return fetch(`${host}/adm/getAjaxComments.php?uid=${uid}`)
    return fetch(url)
    .then((response) => { 
      return response.json() 
    })
    .then((jsonResponse) => {
      console.log('jsonResponse', jsonResponse)
      lastCommentCount = commentCount
      commentCount = jsonResponse[0].count
      //if(lastCommentCount !== commentCount){
      // if(commentCount > 0){
        unreadCommentsArray = jsonResponse[0].tickets_with_new_comments
        console.log('UNREAD COMMENTS ARRAY', unreadCommentsArray, lastUnreadCommentsArray)
        const unchanged = (unreadCommentsArray.length === lastUnreadCommentsArray.length && unreadCommentsArray.every((value, index) => value === lastUnreadCommentsArray[index]))
        console.log('CHANGED', !unchanged)
        if(!unchanged){
        // if(unreadCommentsArray.length >= 1){
          lastUnreadCommentsArray = [...unreadCommentsArray]
          // unreadCommentsArray.forEach((ticketId)=>{
          //   const id = parseInt(ticketId, 10)
          //   dispatch(startEditTicket({id: id, updates: {hasNewComment:true}}))
          // })
          dispatch(setTicketNotifications(unreadCommentsArray))
        }
      //}
      setTimeout(function () { ajax_ugly_poll_new_comments(uid) }, uglyPollTime)
    })
  }
  /** END UGLY AJAX */

  return (
    <div className="theme-light">
      {isLoading ? 
        <div>Loggar in...</div>
      :
      <div className="app-container" id="appContainer">
        <PrivacyModal modalIsOpen={modalIsOpen} handlePrivacyConfirm={handlePrivacyConfirm} /> 
        <Router history={history} >
          <Header />
          <Switch>
            <Route exact path={TicketLocation.path} render={(props) => <PrivateDashboard />} />      
            <ProtectedRoute path="/login" component={Users} />
            <Route exact path={TicketListLocation.path} render={(props) => <PrivateDashboard />} />
            <Route exact path="/">
              <Redirect to="/list" />
            </Route>
            {/**  <Route exact path="/">
            <TicketTableContainer />
          </Route>
          <Route>
            <Redirect path={TicketListLocation.path} render={(props) =><Dashboard />} />
          </Route>       */}
            <Route><p>Hittade inget att visa</p></Route>
          </Switch>

        </Router>
      </div>
      }
    </div>

  )
}

export default AppRouter

function Users() {
  return (
    <React.Fragment>
      <h2>Login</h2>
      <LoginButton />
      <LogoutButton />
      <Profile />
    </React.Fragment>
  );
}