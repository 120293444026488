import React from 'react';
import Modal from 'react-modal';
import { InformationCircleIcon } from '@heroicons/react/outline';


const PrivacyModal = ({modalIsOpen, handlePrivacyConfirm}) => {
  Modal.setAppElement('#root')
  return (
    <Modal
    isOpen={modalIsOpen}
    //onAfterOpen={afterOpenModal}
    //onRequestClose={closeModal}
    contentLabel="Example Modal"
    className="privacyModal"
    overlayClassName="privacyModal__overlay"
    >
    <div className="privacyModal__body">
    <div className="privacyModal__icon"><InformationCircleIcon className="modalIcon" /></div>
    <h3 className="privacyModal__title">Genom att använda Portalen godkänner du Forstena Energi och Kontrolls behandling av personuppgifter.</h3>
    <p> Det innebär även att du tar del av personuppgifter som tredje part och är skyldig att följa Forstenas instruktioner för behandling av personuppgifter.
     Du har inte rätt att lämna ut personuppgifter som återfinns i Portalen till någon annan, eller använda dem för andra ändamål än Portalen avser. </p>
     <a className="privacyModal__link" href="https://www.forstena.se/personuppgifter.html" target="_blank" rel="noreferrer">Läs mer om Forstenas hantering av personuppgifter. </a>
    <div className="privacyModal__btnContainer">
    <button className="privacyModal__confirm" onClick={handlePrivacyConfirm}>Godkänn</button>
    </div>
    </div>
    </Modal>
  )
}

export default PrivacyModal