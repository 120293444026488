export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

const modalDefaultState = {
  isOpen: false
  //modalType
}

const modalsReducer = (state = modalDefaultState, action) => {
  const act = { ...action }
  delete act.type

  switch (action.type) {
    case SHOW_MODAL: 
    return { 
      ...state, 
      ...act, 
      isOpen:true
    }
    case HIDE_MODAL:
      return modalDefaultState
    default: 
    return state
  }
}

export default modalsReducer

