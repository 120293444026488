import {TICKET_ADDRESS, TICKET_COOP, TICKET_APARTMENT, TICKET_CREATED, TICKET_ID, TICKET_RESPONSIBLE, TICKET_STATUS, TICKET_TYPE} from '../reducers/tickets'


const sortTickets = (tickets, sortBy, direction, handleSortDirection) => {
  console.log('dir', direction, handleSortDirection)
  let sortCriteria = [{key: 'id', direction: direction}]
  return tickets.sort((a, b) => {
    let i = 0, result = 0;
    if(sortBy === TICKET_ID) {
      while (i < sortCriteria.length && result === 0) {
        result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));        
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
    }
    if(sortBy === TICKET_COOP) {
      sortCriteria = [{ key: 'coop', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));        
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
    }
    if(sortBy === TICKET_RESPONSIBLE) {
      sortCriteria = [{ key: 'assignedToName', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));        
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
    }
    if(sortBy === TICKET_APARTMENT) {
      sortCriteria = [{ key: 'apartment', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));        
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
    }
    if(sortBy === TICKET_TYPE) {
      sortCriteria = [{ key: 'reportType', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));        
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
    }
    if(sortBy === TICKET_STATUS) {
      sortCriteria = [{ key: 'status', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));        
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
    }
    if (sortBy === TICKET_CREATED) {
      let sortCriteria = [{ key: 'created', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        // result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));
        result = sortCriteria[i].direction * (a[sortCriteria[i].key].localeCompare(b[sortCriteria[i].key], "sv"));
        // a.localeCompare(b, "sv", {sensitivity: 'case'})
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
  }
    else if (sortBy === TICKET_ADDRESS) {
      let sortCriteria = [{ key: 'address', direction: direction }]
      while (i < sortCriteria.length && result === 0) {
        // result = sortCriteria[i].direction * (a[sortCriteria[i].key] < b[sortCriteria[i].key] ? -1 : (a[sortCriteria[i].key] > b[sortCriteria[i].key] ? 1 : 0));
        result = sortCriteria[i].direction * (a[sortCriteria[i].key].localeCompare(b[sortCriteria[i].key], "sv"));
        // a.localeCompare(b, "sv", {sensitivity: 'case'})
        i++;
      }
      handleSortDirection(direction === 1 ? -1 : 1)
      return result
  }
})
}

export const ticketTableHelpers = {
  sortTickets:sortTickets
}
