import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
//import { setTheme } from '../../actions/userSettingsActions';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/outline';
import BurgerMenu from './BurgerMenu';
import { LogoutButton } from '../auth/LogoutButton';
import { useOnClickOutside } from '../../customHooks/useClickOutSide';

const Header = () => {

  //THEME TOGGLE ONLY FOR POC
  // see usersettingsActions.js for info
  /**  const dispatch = useDispatch()
  const toggleSwitch = document.getElementById('appContainer');
  //const currentTheme = 'light'

  const handleSwitch = (e) => {
    //tbd save val to redux
    if (e.target.checked) {
      //sets data-theme on <html tag
      document.documentElement.setAttribute('data-theme', 'dark');
      dispatch(setTheme('dark'))
    }
    else {
      document.documentElement.setAttribute('data-theme', 'light');
      dispatch(setTheme('light'))
    }
  }
  */
  //END THEME TOGGLE

  const userName = useSelector(state => state.auth.nickname)
  const userType = useSelector(state => state.auth.usertype)
  const coop = useSelector(state => state.auth.coop)
  const coopText = (coop !== '') ? `${coop} | ` : '';
  const mediaSize = useSelector(state => state.ui.mediaSize)
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setMenuOpen(false));

  return (
    <header className="header">
      <div className="navbar">
        <div className="navbar__logo"><img src="/ForstenaLogo.svg" alt="Forstena Energi & Kontroll" /></div>
        {mediaSize === 'large'
          ? <nav className="navbar__container">
            {userType === 'objectUser' && <div className="navItem"><span>{coopText}</span></div>}
            {userType === 'portalAdmin' && <div className="navItem"><span><b>ADMIN</b></span></div>}
            <div className="navItem"><span>{userName}</span></div>
            <div className="navItem"><LogoutButton /></div>
          </nav>
          : <div ref={node} className="navbar__kebab" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen
              ? <XIcon className="standardSquareIcon" />
              : <DotsVerticalIcon className="standardSquareIcon" />}</div>
        }
        {/* THEME TOGGLE POC FOR PORTAL
          <div>
          <div className="theme-switch-wrapper">
            <label className="theme-switch" >
              <input type="checkbox" onChange={(e) => handleSwitch(e)} />
              <div className="slider round"></div>
            </label>
            <em>Enable Dark Mode!</em>
          </div>
        </div>
        */}
      </div>
      {menuOpen && <BurgerMenu userName={userName} open={menuOpen} />}
    </header>
  )
}
export default Header