import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { usePromiseTracker } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import {
  TicketLocation
} from '../../routers/RouteSetup';
import { setTextFilter, resetTextFilter, setFilterResponsible, resetFilterResponsible } from '../../actions/filtersActions';
import { filterTickets } from '../../selectors/filterTickets';
import { TicketTableRow } from './TicketTableRow';
import TicketTableRowSmall from './TicketTableRowSmall';
import { AdjustmentsIcon } from '@heroicons/react/outline';

import { TICKET_ADDRESS, TICKET_COOP, TICKET_APARTMENT, TICKET_CREATED, TICKET_ID, TICKET_STATUS, TICKET_TYPE, TICKET_RESPONSIBLE } from '../../reducers/tickets'
import { ticketTableHelpers } from '../../helpers/ticketTableHelpers'
import { calcs } from '../../helpers/calcs';
import { XIcon } from '@heroicons/react/outline'

import { showModal, hideModal } from '../../actions/modalsActions';
import { LoadingSpinnerComponent } from '../LoadingSpinnerComponent'
import ModalWrapper from '../modals/ModalWrapper';


const sortSelectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? '#0074e5'
          : isFocused
            ? '#c3e1ff'
            : null,
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? '#0074e5' : '#c3e1ff')
      },
    };
  },
};

const TicketTable = () => {
  const dispatch = useDispatch()
  let history = useHistory();
  const mediaSize = useSelector(state => state.ui.mediaSize)
  const modalState = useSelector(state => state.modals.isOpen)
  const ticketsActive = useSelector(state => filterTickets(state.tickets, state.filters))
  const ticketsCompleted = useSelector(state => filterTickets(state.ticketsCompleted, state.filters))
  const showComplTickets = useSelector(state => state.ui.showCompleted)

  const ticketNotifications = useSelector(state => state.ticketNotifications)

  const searchText = useSelector(state => state.filters.text)
  const filterValue = useSelector(state => state.filters.filterValue)
  const userType = useSelector(state => state.auth.usertype)
  //const userType = 'portalAdmin'
  //UI
  const { promiseInProgress } = usePromiseTracker({ area: 'ticket-list' });
  const [sortDirection, setSortDirection] = useState(1)
  const [menuPos, setMenuPos] = useState({ x: '50%', y: '50%' })

  //show archive? switch selector
  const tickets = ticketsActive
  const [sortedTickets, setTickets] = useState(tickets)
  const [sortedType, setSortedType] = useState(TICKET_CREATED)

  const [respOptions, setResOptions] = useState([]) //ticket responsible



  const handleRowClick = (id) => {
    history.push((`${TicketLocation.toUrl({ ticketId: id })}`))
  }
  // SEARCH ------------------------------------
  const handleSearch = (text) => {
    //setInputText(text)
    dispatch(setTextFilter(text))
  }

  const clearSearch = () => {
    //setInputText('')
    dispatch(resetTextFilter())
  }
  // END SEARCH --------------------------------

  //SORT------------------------------------
  const handleSortDirection = (dir) => {
    setSortDirection(dir)
  }
 
  const handleSorting = (type) => {
    console.log('fire!!', type)
    const copy = [...tickets] //need to copy array, array.sort isn't considered to be a state change
    const newTickets = ticketTableHelpers.sortTickets(copy, type, sortDirection, handleSortDirection)
    setTickets(newTickets)
    setSortedType(type) //use to update styling on selected headercell/filter
  }

  useEffect(() => {
    setTickets(ticketsActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets.length, ticketNotifications.length])

  //whenever textFilter is changed or completed/active is toggled we want to render with filtered tickets
  useEffect(() => {
    setTickets(tickets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    showComplTickets ? setTickets(ticketsCompleted) : setTickets(ticketsActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComplTickets])

  //END SORT------------------------------------

  // FILTER MENU ------------------------------
  
  // CALC MENU POSITION
  const calcMenuPos = (e) => {
    const modalPosition = calcs.menuPos(e)
    setMenuPos({ x: modalPosition.x, y: modalPosition.y })
  }
  //END CALC CLICK POS
 
  useEffect(() => {
    let allResponsibleOptions = sortedTickets.map((ticket) => (ticket.assignedToName))
    let responsibleOptions = [...new Set(allResponsibleOptions)]
    setResOptions(responsibleOptions)
  }, [sortedTickets, ])


   //get back selected option from modal
   const handleFilterOption = (selected) => {
    dispatch(hideModal())
    //setSelectedFilter(selected)
    dispatch(setFilterResponsible(selected))
    console.log('selected option', selected)
  }

  const clearFilter = () => {
    dispatch(resetFilterResponsible())
  }
  //open filter modal
  const handleFilterModal = (e, bool) => {
    if (bool) {
      calcMenuPos(e)
    }
    dispatch(showModal())
  }

  // END FILTER MENU ------------------------------


  //mediaSize S&M table-sorting
  //performer has 1 more table column
  //const colSpan = userType === 'performer' ? 7 : 6
  let colSpan = 6
  if(userType === 'performer') {
    colSpan= 7
  }
  if(userType === 'portalAdmin') {
    colSpan = 8
  }

  const sortOptionsUser = [
    { value: TICKET_TYPE, label: 'Typ' },
    { value: TICKET_CREATED, label: 'Datum' },
    { value: TICKET_STATUS, label: 'Status' },
    { value: TICKET_ID, label: 'Nr' },
    { value: TICKET_ADDRESS, label: 'Adress' },
    { value: TICKET_APARTMENT, label: 'Lgh.nr' }
  ]

  const sortOptionsPerformer = [
    { value: TICKET_TYPE, label: 'Typ' },
    { value: TICKET_COOP, label: 'BRF' },
    { value: TICKET_CREATED, label: 'Datum' },
    { value: TICKET_STATUS, label: 'Status' },
    { value: TICKET_ID, label: 'Nr' },
    { value: TICKET_ADDRESS, label: 'Adress' },
    { value: TICKET_APARTMENT, label: 'Lgh.nr' }
  ]

  const sortOptionsPortalAdmin = [
    { value: TICKET_TYPE, label: 'Typ' },
    { value: TICKET_COOP, label: 'BRF' },
    { value: TICKET_RESPONSIBLE, label: 'Ansvarig' },
    { value: TICKET_CREATED, label: 'Datum' },
    { value: TICKET_STATUS, label: 'Status' },
    { value: TICKET_ID, label: 'Nr' },
    { value: TICKET_ADDRESS, label: 'Adress' },
    { value: TICKET_APARTMENT, label: 'Lgh.nr' }
  ]


  //let sortOptions = userType ==='performer' ? sortOptionsPerformer  : sortOptionsUser

  let sortOptions = sortOptionsUser

  if(userType === 'performer') {
    sortOptions = sortOptionsPerformer
  }
  if(userType === 'portalAdmin') {
    sortOptions = sortOptionsPortalAdmin
  }


  if (tickets !== null) {
    return (
      <React.Fragment>
       <ModalWrapper type={'filterMenu'} modalOpen={modalState} options={respOptions} handleFilterOption={handleFilterOption} menuPos={menuPos} />
        <div className="flexRow mbM searchWrapper">
          <div>
            <div className="searchContainer">
              <input
                onChange={(e) => { handleSearch(e.target.value) }}
                placeholder="Sök..."
                value={searchText}
              />
              {searchText !== '' && <span className="clearSearch" onClick={() => { clearSearch() }}><XIcon className="standardSquareIcon" /></span>}
            </div>
          </div>
          {filterValue === '' &&
          <div className="mlL">
            <div className="pointer" onClick={(e) => handleFilterModal(e, true)}><AdjustmentsIcon className="standardSquareIcon flip90" /></div>
          </div>
          }
          {filterValue!=='' && <div className="flexRow mlL pointer button button--plain" role="button" onClick={clearFilter}>{filterValue}<XIcon className="standardSquareIcon standardSquareIcon--secondary " /></div>}
        </div>
        <div className="tableOverflow" >
          {mediaSize !== 'large' &&
            <div className="sortWrapper">
              <Select
                placeholder='Sortera'
                options={sortOptions}
                onChange={(value) => handleSorting(value.value)}
                styles={sortSelectStyles}
                value={sortOptions.find(option => option.value === sortedType)}
              />
            </div>
          }
          {promiseInProgress
            ?
            <div className="loaderContainer">
              <LoadingSpinnerComponent area="ticket-list" />
            </div>
            :
            <table style={{ width: '100%' }} className="ticketTable">
              {mediaSize === 'large' && <thead>
                <tr>
                  <th className={sortedType === TICKET_TYPE ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_TYPE)} >Typ</th>
                  {userType !== 'objectUser' && <th className={sortedType === TICKET_COOP ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_COOP)} >BRF</th>
                  }
                  {userType === 'portalAdmin' && <th className={sortedType === TICKET_RESPONSIBLE ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_RESPONSIBLE)} >Ansvarig</th>
                  }
                  <th className={sortedType === TICKET_CREATED ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_CREATED)} >Inkommen</th>
                  <th className={sortedType === TICKET_STATUS ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_STATUS)}>Status</th>
                  <th className={sortedType === TICKET_ID ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_ID)}>Nr</th>
                  <th className={sortedType === TICKET_ADDRESS ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_ADDRESS)}>Adress</th>
                  <th className={sortedType === TICKET_APARTMENT ? 'ticketTable__headerCell selectedSort' : 'ticketTable__headerCell'} onClick={() => handleSorting(TICKET_APARTMENT)}>Lgh.nr</th>
                </tr>
              </thead>
              }

              {sortedTickets.length < 1
                ? <tbody><tr><td colSpan={colSpan}>Inget att visa</td></tr></tbody>
                : sortedTickets.map((ticket) => {
                  const hasNewComment = ticketNotifications.includes(ticket.id)
                  ticket.hasNewComment = hasNewComment
                  return (
                    mediaSize === 'large'
                      ? <TicketTableRow key={ticket.id} ticket={ticket} handleRowClick={handleRowClick} userType={userType} />
                      : <TicketTableRowSmall key={ticket.id} ticket={ticket} handleRowClick={handleRowClick} userType={userType} />
                  )
                })}

            </table>
          }
        </div>
        {/*<p>Remove after test:</p>
        <ul>
          <li>
            <Link to={{ pathname: TicketListLocation.path }}>Lista</Link>
          </li>

          <li>
            <Link to="/pub">Public only</Link>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
        </ul>*/}
      </React.Fragment>
    )
  }
  return <p>...</p>

}

export default TicketTable
