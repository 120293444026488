import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { PencilAltIcon } from '@heroicons/react/outline';
import {startAddComment} from '../../../actions/commentsActions';

const CommentAdd = ({ticketId}) => {
  const [commentText, setCommentText] = useState('')
  const dispatch = useDispatch()
  const uid = useSelector(state => state.auth.sub)
  const author = useSelector(state => state.auth.name)
  const mediaSize = useSelector(state => state.ui.mediaSize)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      dispatch(startAddComment({comment:commentText, author, ticketId, uid}))
      setCommentText('')
      return false;
    }
    if (e.key === 'Enter' && e.shiftKey) {
      console.log('shift-enter was pressed')
    }
  }
  return (
    <div className="">
      <form>
        <div className="commentAdd__row">
          {mediaSize === 'large' && <PencilAltIcon className="standardSquareIcon" />}
          <TextareaAutosize
            className="textarea"
            maxLength={1024}
            minRows={3}
            onChange={(e) => { setCommentText(e.target.value) }}
            onKeyDown={handleKeyDown}
            placeholder="Skriv din kommentar och tryck Enter"
            value={commentText}
          />
        </div>
      </form>
    </div>
  )
}

export default CommentAdd;