import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux'
import { hideModal } from '../../actions/modalsActions';
// const filterModalStyle = {
//   content: {
//     top: '30%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }


//to make this reusable, pass prop 'type' and return different <Modal> for each type!
const ModalWrapper = (props) => {
  console.log('modal props', props)
  Modal.setAppElement('#root')
  const dispatch = useDispatch()
  
  console.log('modal type', props.type)
  return (
    <Modal
      isOpen={props.modalOpen}
      //onAfterOpen={afterOpenModal}
      onRequestClose={() => dispatch(hideModal())}
      contentLabel="Example Modal"
      className="filterModal"
      style={{
        overlay: {
          backgroundColor: 'rgba(255,255,255,0)', zIndex:'1000'
        }, 
        content: { 
          top: `${props.menuPos.y}px`, 
          left: `${props.menuPos.x}px`, 
          height: 'max-content', 
          width: 'max-content', 
          padding: '0' }
      }
      }
    //overlayClassName="modalOverlay--transparent"

    >
      <ul className="menuList">
        {props.options.map((option) => (
          <li className="menuList__option ptXS pbXS plXS prXS" onClick={()=>props.handleFilterOption(option)}>{option}</li>)
        )}
      </ul>

    </Modal>
  )
}

export default ModalWrapper;
