import React, { useRef, useState, useLayoutEffect } from 'react';
import { KeyIcon } from '@heroicons/react/outline';
import { CogIcon } from '@heroicons/react/outline';
import { ChatIcon } from '@heroicons/react/outline';

const TicketTableRowSmall = ({ ticket, handleRowClick, userType }) => {
  const tRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (tRef.current) {
      setDimensions({
        width: tRef.current.offsetWidth - 20,
        height: tRef.current.offsetHeight
      });
    }
  }, []);

  //let icon= <XIcon className="standardSquareIcon"/> 
  //TBD select icon depending on reportType


  return (
    <tbody className="ticketTable__smallRow" onClick={() => handleRowClick(ticket.id)} ref={tRef}>
      <tr className="test">
        <td>
          <p className="singleLine title">
          {(ticket.reportType === 1 || ticket.reportType === 2) ? <KeyIcon className="standardSquareIcon"/> : <CogIcon className="standardSquareIcon"/> }
          {ticket.hasNewComment && <ChatIcon className="standardSquareIcon  standardSquareIcon--notification"/>}
          <span>{ticket.created}</span>
          </p>
          <p className="singleLine flex"><span>Id: {ticket.id}</span><span>{ticket.address}</span></p>
          {(userType === 'performer' || userType === 'portalAdmin') && <p className="singleLine flex"><span></span><span>{ticket.coop}</span></p>}
        </td>
      </tr>
      <tr>
        <td>
          <div className="desc" style={{ maxWidth: dimensions.width }}>{ticket.descr}</div>
        </td>
      </tr>
    </tbody>
  )
}

export default TicketTableRowSmall