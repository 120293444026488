import React from 'react';
import { LogoutIcon } from '@heroicons/react/outline';

const BurgerMenu = ({ open, userName}) => {
  return (
    <div className={open ? "burgerMenu translNo" : "burgerMenu translFull"} >
      <a href="/">
        
           {userName}
      </a>
      <a href="/">
        
      <LogoutIcon className="burgerMenu__icon"/> 
      <span>LOGGA UT</span>
      </a>
    </div>

  )
}

export default BurgerMenu