import { SHOW_MODAL, HIDE_MODAL } from '../reducers/modals'

export const showModal = (payload) => ({
  type: SHOW_MODAL,
  ...payload
})

export const hideModal = () => ({
  type: HIDE_MODAL
})
