import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PrinterIcon } from '@heroicons/react/outline';
import { ArchiveIcon } from '@heroicons/react/outline';
import { ViewListIcon } from '@heroicons/react/outline';
import { startSetCompletedTickets} from '../../actions/ticketsActions';
import {setShowCompleted } from '../../actions/ui';

//import { DotsVerticalIcon } from '@heroicons/react/outline'

//TBD make kebab for BE portal

const TicketTableActionRow = () => {
  const dispatch = useDispatch()
  const mediaSize = useSelector(state => state.ui.mediaSize)
  const showCompleted = useSelector(state => state.ui.showCompleted)
  const uid = useSelector(state => state.auth.sub)
  const userType = useSelector(state => state.auth.usertype)
  const coop = useSelector(state => state.auth.coop)
  const coopText = (userType === 'objectUser' && coop !=='') ? ` - BRF ${coop}` : ''

  const handlePrint = () => {
    document.title = `Forstena Portalen${coopText}`
    window.print()
  }

  const handleGetArchive =  () =>{
    //TBD condotional render LI and have two actions instead of checking showCompleted here!
    dispatch(startSetCompletedTickets(uid))
    //if(showCompleted){ dispatch(setShowCompleted(false)) }
  }

  const handleShowActive = () => {
    //dispatch action to show active tickets
    dispatch(setShowCompleted(false))
  }
  return(
    <ul className="ticketTable__actionRow">
    {!showCompleted && <li onClick={handleGetArchive}><ArchiveIcon className="standardSquareIcon"/>Arkiv</li>}
    {showCompleted && <li onClick={handleShowActive}><ViewListIcon className="standardSquareIcon"/>Aktiva</li>}
      <li onClick={handlePrint}><PrinterIcon strokeWidth={4} className="standardSquareIcon"/>{mediaSize==='large' && <span>Print</span>}</li>
      {/*<li><DotsVerticalIcon className="standardSquareIcon"/></li>*/}
    </ul>
  )
}

export default TicketTableActionRow