import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  console.log('user', user)

  return (
    isAuthenticated && (
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name} ({user.nickname})</h2>
        <p>{user.email}</p>
        <p>Updated at: {user.updated_at}</p>
        <p>{(user.email_verified) ? 'Verified email' : 'Unverified email'}</p>
      </div>
    )
  );
}
