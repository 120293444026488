import { useState, useEffect, useRef } from 'react';

// Hook
export const useWindowSize = () => {
  //Object represents browser window
  const isClient = typeof window === 'object';
  const lastWidth = useRef();
  const lastHeight = useRef();
  function getSize() {
    // console.log('BBB windowSize', windowSize)
    // console.log('BBB window.innerWidth', window.innerWidth)
    lastWidth.current = window.innerWidth
    lastHeight.current = window.innerHeight
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    //Exit if not user/browser
    if (!isClient) { return false }

    function handleResize() {
      // console.log('BBB lastWidth', lastWidth)
      // console.log('BBB lastWidth.current', lastWidth.current)
      // console.log('BBB window?.innerWidth', window?.innerWidth)
      // console.log('BBB (window?.innerWidth !== lastWidth.current)', (window?.innerWidth !== lastWidth.current))
      if (window?.innerWidth !== lastWidth.current || window?.innerHeight !== lastHeight.current) {
        console.log('useWindowSize CHANGED')
        // const width = getSize().width;
        // lastWidth.current = width;
        // setWindowSize(width)
        setWindowSize(getSize())
      }else{
        console.log('useWindowSize has not changed')
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}