import {
  SET_MEDIA_SIZE, 
  SHOW_COMPLETED ,
  SHOW_PRIVACY_INFO,
  INITIAL_FETCH_COMPLETED_TICKETS
} from '../reducers/ui'

export const setMediaSize = (mediaSize) => ({
  type: SET_MEDIA_SIZE,
  mediaSize: mediaSize
})

export const setShowCompleted = (showCompleted) => ({
  type: SHOW_COMPLETED, 
  showCompleted:showCompleted
})

export const setinitTicketFetchCompleted = (completed) => ({
  type: INITIAL_FETCH_COMPLETED_TICKETS, 
  completed:completed
})

export const setShowPrivacyInfo = (showPrivacyInfo) => ({
  type: SHOW_PRIVACY_INFO, 
  showPrivacyInfo:showPrivacyInfo
})

//fetch data and dispatch SET_TICKETS
export const startSetShowPrivacyInfo = (uid) => {
  // console.log('startSetShowPrivacyInfo', uid)
  return (dispatch) => {
    const host = process.env.REACT_APP_AJAX_HOST
    const dataLocation = process.env.REACT_APP_DATA_LOCATION
    const url = (dataLocation === 'local') ? '/testSuccessResponse.json' : `${host}/adm/goPrivacy.php?uid=${uid}&act=read`
    
    return fetch(url)
    .then((response) => { return response.json() })
    .then((jsonResponse) => {
      const isRead = jsonResponse[0]?.isRead ?? false
      const show = (isRead) ? false : true
      return Promise.resolve(dispatch(setShowPrivacyInfo(show)))
    })
    .catch((error) => console.log('error', error))
  }
}